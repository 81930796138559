import React, { FC, MutableRefObject } from 'react'

import { differences, joinSimplus } from './items'

import styles from './styles.module.css'

type ItemsTypes = {
  label: string
  image: string
}
interface PropTypes {
  WhySimplusRef: MutableRefObject<HTMLDivElement | null>
}

const Features: FC<PropTypes> = ({ WhySimplusRef }) => {
  const renderItems = (items: ItemsTypes[], longDescription?: boolean) => {
    return items.map((item, idx) => {
      return (
        <div key={idx.toString()} className={styles.item}>
          <div className={styles.image}>
            <img src={item.image} alt={Math.random().toString()} />
          </div>
          <div
            className={
              longDescription ? styles.longDescription : styles.description
            }>
            {item.label}
          </div>
        </div>
      )
    })
  }

  const renderDifferences = () => {
    return (
      <div className={styles.content}>
        <div className={styles.head}>
          <div className={styles.title}>
            ¿Qué nos <b> diferencia? </b>
          </div>
          <div className={styles.divider} />
        </div>
        <div className={styles.items}>{renderItems(differences, true)}</div>
      </div>
    )
  }

  const renderJoinSimplus = () => {
    return (
      <div className={styles.content}>
        <div className={styles.head}>
          <div className={styles.title}>
            ¿Por qué unirte a <b> Simplus? </b>
          </div>
          <div className={styles.divider} />
        </div>
        <div className={styles.items}>{renderItems(joinSimplus)}</div>
      </div>
    )
  }

  return (
    <div className={styles.container} ref={WhySimplusRef}>
      {renderJoinSimplus()}
      <div className={styles.divider} />
      {renderDifferences()}
    </div>
  )
}

export default Features
