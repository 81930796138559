import React, { FC, useRef } from 'react'

import { Navbar } from '../../../components/navbar/Navbar'
import { ButtonToNavbar } from '../ButtonsToNavbar'

import Bottom from './Bottom'
import Buy from './Buy'
import Clients from './Clients'
import Comments from './Comments'
import Features from './Features'
import Footer from './Footer'
import HowItWorks from './How-It-Works'
import Manage from './Manage'
import TopContent from './Top-Content'

import styles from './styles.module.css'

const NewLanding: FC = () => {
  const HowItWorksRef = useRef<HTMLDivElement | null>(null)
  const WhySimplusRef = useRef<HTMLDivElement | null>(null)

   const linkMenu = [
    {
      id: 1,
      name: '¿Por qué Simplus?',
      ref: WhySimplusRef,
    },
    {
      id: 2,
      name: '¿Cómo funciona?',
      ref: HowItWorksRef,
    },
    {
      id: 3,
      name: 'Contáctanos',
      link: '/app/solicitud-demo',
    },
  ]

  const scrollTo = (
    ref: React.MutableRefObject<HTMLDivElement | null>,
  ): void => {
    setTimeout(
      () =>
        window.scrollTo({ top: (ref.current?.offsetTop ?? 0)  - 100, behavior: 'smooth' }),
      100,
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.topContent}>
        <Navbar
          ButtonOrOptions={ButtonToNavbar}
          linkMenu={linkMenu}
          scrollTo={scrollTo}
          isTermsAndCondition={false}
          active={0}
        />
        <TopContent />
      </div>
      <HowItWorks HowItWorksRef={HowItWorksRef} />
      <div className={styles.divider} />
      <Manage />
      <div className={styles.divider} />
      <Buy />
      <Features WhySimplusRef={WhySimplusRef} />
      <Clients />
      <Comments />
      <Bottom />
      <Footer />
    </div>
  )
}

export default NewLanding
