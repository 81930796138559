import { gql } from '@apollo/client'

export const COMMENTS = gql`
  query getComments @api(name: "users") {
    getComments {
      _id
      name
      photo
      comment
      memberSince
    }
  }
`
