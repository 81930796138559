import React, { FC } from 'react'

import journeyImageSrc from './images/journeys.png'
import operators from './operators'

import styles from './styles.module.css'

const Buy: FC = () => {
  const renderOperators = () => {
    return operators.map((operator, idx) => {
      return (
        <div key={idx.toString()} className={styles.operator}>
          <img src={operator} />
        </div>
      )
    })
  }

  const renderLeftInfo = () => {
    return (
      <div className={styles.leftPanel}>
        <div className={styles.title}>
          Elige
          <br /> <b>la mejor oferta</b>
        </div>
        <div className={styles.description}>
          Accede a una oferta de viajes de manera centralizada de los operadores
          Turbus, Cóndor, JAC y Biobio.
        </div>
        <div className={styles.operators}>{renderOperators()}</div>
      </div>
    )
  }

  const renderRightImage = () => {
    return (
      <div className={styles.rightPanel}>
        <img src={journeyImageSrc} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {renderLeftInfo()}
        {renderRightImage()}
      </div>
    </div>
  )
}

export default Buy
