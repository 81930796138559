import bus from './images/bus.png'
import checks from './images/checks.png'
import person from './images/person.png'
import screen from './images/screen.png'
import seat from './images/seat.png'
import sound from './images/sound.png'
import stars from './images/stars.png'
import union from './images/union.png'
import waiting from './images/waiting.png'

export const joinSimplus = [
  {
    label: 'Acceso a crédito personalizado',
    image: stars,
  },
  {
    label: 'Eliges el mejor horario de viaje para tus colaboradores',
    image: bus,
  },
  {
    label: 'Gestionas la devolución en línea de tus pasajes y facilita proceso facturación',
    image: union,
  },
  {
    label: 'Controlas tus costos y obtienes información en línea',
    image: screen,
  },
  {
    label: '100% autogestión de tus colaboradores',
    image: person,
  },
]

export const differences = [
  {
    label: 'Ahorras tiempo y asignas los asientos de manera aleatoria',
    image: seat,
  },
  {
    label: 'Seguimiento del embarque de tus pasajeros',
    image: waiting,
  },
  {
    label:
      'Dale a tus compradores permisos personalizados para cada una de sus compras',
    image: checks,
  },
  {
    label: 'Atención personalizada',
    image: sound,
  },
]
