import React, { FC, MutableRefObject } from 'react'

import image from './images/how-it-works.png'

import styles from './styles.module.css'

interface PropTypes {
  HowItWorksRef: MutableRefObject<HTMLDivElement | null>
}

const HowItWorks: FC<PropTypes> = ({ HowItWorksRef }) => {
  const renderLeftInfo = () => {
    return (
      <div className={styles.leftPanel}>
        <div className={styles.register}>Regístrate</div>
        <div className={styles.description}>
          Regístrate de manera simple en tan solo unos pocos pasos.{' '}
        </div>
        <div className={styles.message}>
          ¡Puedes solicitar crédito personalizado dentro de la plataforma Simplus!
        </div>
      </div>
    )
  }

  const renderRightImage = () => {
    return (
      <div className={styles.rightPanel}>
        <img src={image} alt={Math.random().toString()} />
      </div>
    )
  }

  return (
    <div className={styles.container} ref={HowItWorksRef}>
      <div className={styles.title}>
        ¿Cómo <b>funciona?</b>
      </div>
      <div className={styles.content}>
        {renderLeftInfo()}
        {renderRightImage()}
      </div>
    </div>
  )
}

export default HowItWorks
