import React from 'react'

import SEO from '../components/seo'
import { LandingPage } from '../screens/landing-page'

import 'antd/dist/antd.css'

const LadingPage = (): JSX.Element => (
  <>
    <SEO title="inicio" />
    <LandingPage />
  </>
)

export default LadingPage
