import React, { FC } from 'react'

import buyerImageSrc from './images/buyer.png'
import journeyImageSrc from './images/journey.png'
import journeyImageSrcMobile from './images/mobile.png'

import styles from './styles.module.css'

const Manage: FC = () => {
  const renderLeftInfo = () => {
    return (
      <div className={styles.leftPanel}>
        <div>
          <div className={styles.title}>
            Administra <b>todo</b>
          </div>
          <div className={styles.description}>
            Gestiona todos los datos de viaje de la empresa como tus
            compradores, tus pasajeros, tus viajes y más.
          </div>
        </div>
        <div className={styles.image}>
          <img src={buyerImageSrc} />
        </div>
      </div>
    )
  }

  const renderRightImage = () => {
    const isBrowser = () => typeof window !== 'undefined'
    const mobileImage =
      isBrowser() && window?.innerWidth < 500
        ? journeyImageSrcMobile
        : journeyImageSrc

    return (
      <div className={styles.rightPanel}>
        <img src={mobileImage || ''} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {renderLeftInfo()}
        {renderRightImage()}
      </div>
    </div>
  )
}

export default Manage
