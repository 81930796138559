import React, { FC } from 'react'
import { ArrowDownOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { navigate } from 'gatsby'

import image from './images/phone-mac.png'

import styles from './styles.module.css'

const TopContent: FC = () => {
  return (
    <>
      <div className={styles.container}>
        <Row className={styles.content} justify="center" align="middle">
          <Col>
            <div className={styles.title}>
              Comprar los pasajes <br /> para tus colaboradores <br />
              <div className={styles.moreSimplus}>
                AHORA ES <br />
                MÁS SIMPLUS
              </div>
            </div>
            <div className={styles.description}>
              Simplus, la nueva forma de comprar los <br /> pasajes para tu
              empresa. Gestiona de <br />
              manera simple horarios, buses, asientos,
              <br /> facturas, devoluciones y mucho más. Una <br /> solución en
              transporte empresarial con todo <br /> en un mismo lugar.
            </div>
            <div className={styles.buttonContent}>
              <Button
                className={styles.button}
                onClick={() => navigate('/app/solicitud-demo')}>
                Solicitar demo ahora
              </Button>
            </div>
            <Row justify="end" align="bottom" className={styles.height59}>
              <Col xs={0} lg={12} className={styles.knowMoreFont}>
                <Row justify="end">
                  <Col className={styles.centered}>
                    <div>Conoce más</div>
                    <ArrowDownOutlined />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <img src={image} className={styles.devices} />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default TopContent
