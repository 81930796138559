import React, { FC } from 'react'
import { Carousel } from 'antd'

import { firstPanel } from './items'

import './carouselStyles.css'
import styles from './styles.module.css'

const Clients: FC = () => {
  const renderCarousel = () => {
    return (
      <Carousel>
        <div>
          <div className={styles.items}>
            {firstPanel.map((image, idx) => {
              return (
                <img
                  src={image}
                  key={idx.toString()}
                  alt={Math.random().toString()}
                />
              )
            })}
          </div>
        </div>
      </Carousel>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Nuestros <b>clientes</b>
      </div>
      <div className={styles.carousel}>{renderCarousel()}</div>
    </div>
  )
}

export default Clients
