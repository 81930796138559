import React, { FC } from 'react'
import { Button } from 'antd'
import { navigate } from 'gatsby'

import MacPic from './images/macbook-air.png'

import styles from './styles.module.css'

const Comments: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.pic}>
          <img src={MacPic} alt={Math.random().toString()} />
        </div>
        <div className={styles.demo}>
          <div className={styles.title}>
            Simplus, tu solución en transporte empresarial
          </div>
          <Button
            className={styles.button}
            onClick={() => navigate('/app/solicitud-demo')}>
            Solicitar demo
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Comments
