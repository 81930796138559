import React, { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Carousel } from 'antd'
import { isEmpty } from 'ramda'

import Loading from '../../../../components/LoadingSpinner'

import { COMMENTS } from './queries.graphql'

import styles from './styles.module.css'

type CommentsTypes = {
  _id: string
  name: string
  photo: string
  comment: string
  memberSince: number
}

const Comments: FC = () => {
  const [comments, setComments] = useState<CommentsTypes[]>([])

  const { loading } = useQuery(COMMENTS, {
    variables: {},
    onCompleted: data => {
      const { getComments } = data
      if (getComments) setComments(getComments)
    },
  })

  const renderCarousel = () => {
    if (loading) return <Loading />
    return (
      <Carousel>
        {comments.map((item, idx) => {
          return (
            <div key={idx.toString()}>
              <div className={styles.client}>
                <img src={item.photo} alt={Math.random().toString()} />
                <div className={styles.info}>
                  <div className={styles.name}>{item.name}</div>
                  <div className={styles.description}>
                    "{item.comment}" - Cliente desde {item.memberSince}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Carousel>
    )
  }

  if (isEmpty(comments)) return <span />
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Testimonios de <b>nuestros clientes</b>
      </div>
      <div className={styles.carousel}>{renderCarousel()}</div>
    </div>
  )
}

export default Comments
