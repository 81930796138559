import atcoLogo from './images/atco-logo.png'
import exconLogo from './images/construccion-excon.png'
import getmLogo from './images/getm.png'
import tricominLogo from './images/logo-tricomin-header.png'
import miningLogo from './images/miningheader.png'

export const firstPanel = [
  exconLogo,
  getmLogo,
  atcoLogo,
  tricominLogo,
  miningLogo,
]
